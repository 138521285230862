import React from 'react';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import {IIdentity} from '../controller/identity';

export interface IFooterProps {
    identity?: IIdentity;
}

export const Footer: React.FunctionComponent<IFooterProps> = (props) => {

    const links: any[] = [];
    const targets: any[] = [];

    const help = props.identity ? <div>
        <div className="block"><span style={{fontWeight: "bold", color: "#444"}}>HILFE</span></div>
        <div className="block" style={{marginTop: "-1rem"}}>
            <div>
                <a href="https://www.institut-sitya.at/business-video-training" target="_blank"
                   rel="noreferrer noopener">
                    Business Videokurs
                </a>
            </div>
            <div>
                <a href="https://www.institut-sitya.at/online-praesenzseminare" target="_blank"
                   rel="noreferrer noopener">
                    Online-Präsenzseminare
                </a>
            </div>
            <div>
                <a href="https://www.institut-sitya.at/e-learning-anleitung" target="_blank" rel="noreferrer noopener">
                    E-Learning Anleitung
                </a>
            </div>
            <div>
                <a href="https://share.hsforms.com/1pgb0ukp6QmKLMMPMvjGZKg3hhdt" target="_blank"
                   rel="noreferrer noopener">
                    Lernhilfe & Support
                </a>
            </div>
        </div>
    </div> : undefined;

    if (props.identity) {

        targets.push(<div key="item-2a"><Link to="/">Startseite</Link></div>);
        targets.push(<div key="item-2b"><Link to="/kurse"><span className="translate">Meine Kurse</span></Link></div>);

        targets.push(<div key="item-2d"><Link to="/logout"><span className="translate">Abmelden</span></Link></div>);

        links.push(
            <div key="item-1" className="block"><span className="translate"
                                                      style={{fontWeight: "bold", color: "#444"}}>LINKS</span></div>
        );

        links.push(
            <div key="item-2" className="block" style={{marginTop: "-1rem"}}>
                {targets}
            </div>
        );
    }

    return (
        <footer className="footer" style={{backgroundColor: "white"}}>
            <div className="container">
                <div className="social has-text-right">
                    <span style={{paddingRight: "0.5rem"}}></span>
                    <a href="https://www.facebook.com/SITYA.Institut" target="_blank" rel="noreferrer noopener">
                        <FontAwesomeIcon icon={faFacebookSquare} className="fa-2x"/>
                    </a>
                </div>
                <hr/>
                <div className="footercontent columns is-multiline">
                    <div className="column is-4-tablet">
                        <div className="block"><span style={{fontWeight: "bold", color: "#444"}}>INSTITUT SITYA</span>
                        </div>
                        <div className="block" style={{marginTop: "-1rem"}}>
                            <div>Karlsplatz 1/4/14</div>
                            <div>A-1010 Wien</div>
                        </div>
                        <div className="block" style={{marginTop: "-1rem"}}>
                            <div><a href="https://www.institut-sitya.at/impressum" target="_blank"
                                    rel="noreferrer noopener"><span className="translate">Impressum</span></a></div>
                            <div><a href="https://www.institut-sitya.at/datenschutz" target="_blank"
                                    rel="noreferrer noopener"><span
                                className="translate">Datenschutzerklärung</span></a></div>
                            <div><a href="https://www.institut-sitya.at/agb" target="_blank"
                                    rel="noreferrer noopener"><span className="translate">AGB</span></a></div>
                        </div>
                    </div>
                    <div className="column is-4-tablet">
                        {links}
                    </div>
                    <div className="column is-4-tablet has-text-right-tablet">
                        {help}
                    </div>
                </div>
            </div>
        </footer>);
}
